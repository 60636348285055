import { Injectable } from '@angular/core';
import {
  BehaviorSubject, catchError, interval, map, mergeMap,
  Observable, of, retry, startWith,
  Subscription,
  switchMap, tap, throwError
} from "rxjs";
import {UserModel} from "../models/user.model";
import {HttpClient} from "@angular/common/http";
import {ErrorResponse, ResponseModel} from "../models/response.model";
import {UserService} from "./user.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ChatService} from "./chat.service";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {TrackingHelperService} from "./tracking-helper.service";
import {TawkService} from "./tawk.service";
import {ConfigService} from "./config.service";
import * as Sentry from "@sentry/browser";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
  * handles login AND logged-in user related things
  * at initialization checks if the user is still logged in - getUserInfo (if user refreshes the page or navigates away)
  * stores authToken (and cashierUrl) for logged-in user
  * keeps user logged in = keepAlive in every minute
  * logs out user from server: logOut
  * logs out user from frontend: resetUserSession
  * handles password resetting resetUserPassword
  * */

  BASE_URL: string;
  CASINO_ID: number;

  private _token = new BehaviorSubject<string | null>(null);
  private _cashierUrl = new BehaviorSubject<string | null>(null);

  private keepAliveSub?: Subscription;
  user!: UserModel | null;

  constructor(
    private http: HttpClient,
    private configs: ConfigService,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private chatService: ChatService,
    private gaService: GoogleAnalyticsService,
    private trackingService: TrackingHelperService,
    private tawkService: TawkService
  ) {
    this.BASE_URL = this.configs.apiUrl;
    this.CASINO_ID = this.configs.casinoId;
    this.userService.userObject.subscribe((u: UserModel | null) => {
      this.user = u;
    })

    /** INITIALIZATION
      if user is logged in (refreshed the page, navigated away, etc)
      - set sessionToken and cashierUrl from localStorage
      - get userInfo: to see if the token is still valid (if yes, we get the userObjected in userService and we start the keepalive
    */

    this.restoreTokenAndUrlFromLocalStorage();

    if (this.token.value) {
      this.userService.getUserInfo().subscribe({
          next: (user)=> {
            if (user){
              //we have the user logged in and the token is still valid (ex. page reloaded/refreshed)
              this.startKeepAlive();

              //initialize Rival chat for logged in users
              if (this.configs.chatSystem === "rival"){
                this.chatService.initialize(true)
                //todo fix "chat already initialized" error - maybe comes from here? see on $entry
                //10/04/2025 update: the error comes from chatComponent - where the chatUserSubs gets the user later than the initialize is called when ex. user refreshes the page and clicks too fast on chat button
                //since it does not disturbs user experience, we put them in a try-catch block to handle error (not reported in sentry

              }

              //login for tawk chat - not needed, if user was logged in, tawk.to remembers
              // if (this.chatSystem === "tawk"){
              //   this.tawkService.LoginUserToTawk(result.user)
              // }

              //send event to GA ex. page_load that is not used for anything else TODO find out if we need this
              // this.gaService.event('page_load', undefined, undefined, undefined, undefined,{...this.trackingService.getAffiliateOptions() });
            }

          },
          error: (err) => {
            //401 invalid token error handled in interceptor = resetUserSession, other errors shown to user:
            if(err.message === 'Could not authorize request.'){ //request without token
              this.resetUserSession();
              this.toastr.error(this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE'), `${this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_H1')}`, {toastClass: 'ngx-toastr yourclass'})
            } else {
              console.error('Could not fetch userDetails while restoring page', err)
              this.toastr.error(`${err.message}. ${this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_TIP')}`, `${this.translate.instant('LOGIN.TOASTR_ERROR_NOUSERINFO_MESSAGE')}`, {toastClass: 'ngx-toastr yourclass'})
            }

          }
        }
      )
    }
  }

  /** LOGIN
   * from log in modal OR after successful registration (regSTep4 minRegModal)
   * gets token and cashierUrl from API
   * gets userInfo - this is after what we can do anything else
   * starts keepAlive
   * initializes chat
   * */

  login(userLog: { username: string, password: string, mode:string }): Observable<ResponseModel> {
    //if the chat was opened as guest user - closes chat and ws
    this.chatService.toggleChat(false);

    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"session.login","params":userLog})
      .pipe(map((loginResponse: ResponseModel) => {
        if ('error' in loginResponse){
          throw loginResponse
        }

        if ('result' in loginResponse && loginResponse.result) {
          const result = loginResponse.result as { session: string; cashierUrl: string }
          if (result.session) {
            try {
              localStorage.setItem('sessionToken', result.session);
            } catch (error) {
              console.warn('Error accessing localStorage while login:', error);
            }
            this._token.next(result.session)

            // moved keepALive and Rival chat initialization into getUserInfo's subs

            /* old version of getUSerInfo subs before refactoring
            this.userService.getUserInfo().subscribe({
              next: (response) => {
                if ('result' in response && response.result) {
                  const result = response.result as { user: UserModel }
                  if (result && result.user) {

                    this.gaService.event('login', undefined, undefined, undefined, undefined, {
                      user_id: result.user.login,
                      user_uuid: result.user.id, ...this.trackingService.getAffiliateOptions()
                    });

                    //login for tawk chat
                    if (this.configs.chatSystem === "tawk") {
                      this.tawkService.LoginUserToTawk(result.user)
                    }
                    this.toastr.success(this.translate.instant('LOGIN.TOASTR_SUCCESS_MESSAGE', {username: result.user.fname}), this.translate.instant('LOGIN.TOASTR_SUCCESS_H1'), {toastClass: 'ngx-toastr yourclass'});
                  }
                }
              },
              error: (err) => {
                //todo fix ERROR after registration - first login is successful, than user gets blocked and getUserInfo fails
                //find out when this comes ex. after login, the user is blocked, after login the network is blocked
                console.error("Error after login in getUserInfo:", err);
              }
            })
            */



            this.userService.getUserInfo().subscribe({
              next: (user) => {
                if (user) {
                    this.gaService.event('login', undefined, undefined, undefined, undefined, {
                      user_id: user.login,
                      user_uuid: user.id, ...this.trackingService.getAffiliateOptions()
                    });

                    //moved keepALive and chatInit here -  todo check - problem on GP too fast requests
                    this.startKeepAlive();
                    //initialize Rival chat for logged in users
                    if (this.configs.chatSystem === "rival") {
                      this.chatService.initialize(true)
                    }

                    //login for tawk chat
                    if (this.configs.chatSystem === "tawk") {
                      this.tawkService.LoginUserToTawk(user)
                    }

                    this.toastr.success(this.translate.instant('LOGIN.TOASTR_SUCCESS_MESSAGE', {username: (user.fname??user.login)}), this.translate.instant('LOGIN.TOASTR_SUCCESS_H1'), {toastClass: 'ngx-toastr yourclass'});
                  }

              },
              error: (err) => {
                if (err.message?.includes('Could not authorize request.')){ //request without token
                  this.resetUserSession();
                  this.toastr.error(`${err.message} ${this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE')} ${this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_TIP')}`, `${this.translate.instant('LOGIN.TOASTR_FAILED_H1')}`, {toastClass: 'ngx-toastr yourclass'})
                } else {
                  console.error('Could not fetch user details after login', err)
                  this.toastr.error(`${err.message}. ${this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_TIP')}`, `${this.translate.instant('LOGIN.TOASTR_ERROR_NOUSERINFO_MESSAGE')}`, {toastClass: 'ngx-toastr yourclass'})
                }
              }
            })

          }

          if (result.cashierUrl) {
            try {
              localStorage.setItem('cashierUrl', result.cashierUrl);
            } catch (error) {
              console.warn('Error accessing localStorage while login:', error);
            }
            this._cashierUrl.next(result.cashierUrl)
          }

        }
        return loginResponse;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
      )

  }


  resetPassword(userLog:{email: string}): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"user.resetPassword","params": userLog})
      .pipe(
        map( (response: ResponseModel) => {
            if ('error' in response){
              throw response;
            }
            return response
          }
        ),
        catchError((error) => {
          return throwError(()=> error)
        })
      )
  }





  startKeepAlive() {
    this.keepAliveSub?.unsubscribe();
    this.keepAliveSub = interval( 60 * 1000) // refreshes user balance in every minute
      .pipe(
        startWith(0),
        switchMap(() => this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"session.keepAlive","params":{} })
          .pipe(
            // retry(3), //retries error with NOT 200 OK status code - if no success, then keepAlive is ended
            retry({count: 3, delay: 1000}), //retries every second when there is an error with NOT 200 OK status code - if no success, then keepAlive is ended
            mergeMap(response => {
              if ('result' in response && 'balance' in response.result) {
                return of(response.result as {balance: number});
              }
              return throwError(() => response)
            })
          )
        )
      )
      .subscribe(
        {next:(response: {balance: number}) => {
          // console.log('KA started');
          const balance = response.balance;
          if (balance !== undefined) {
            const user = this.user;
              if (user) {
                user.balance = balance;
                this.userService.updateUser(user);

                this.gaService.event('balance', undefined, undefined, undefined, undefined, {user_balance: balance, ...this.trackingService.getAffiliateOptions()});

                //todo add event to tawk balance change
                if (this.configs.chatSystem === "tawk") {
                  // this.tawkService.addEventToTawk('balance') //not working at all
                  // this.tawkService.UpdateTawkUser(user); // Error: unauthorized_api_call https://community.tawk.to/t/unauthorized-api-call/1495
                }
              }
          } else {
            console.error('Invalid response structure:', response);
          }
        }, error: err => {
          if(err.error?.message === 'Could not authorize request.'){ //request without token
            this.resetUserSession();
            this.toastr.error(this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE'), `${this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_H1')}`, {toastClass: 'ngx-toastr yourclass'})
          } else {
            console.error('Could not fetch balance', err)
            this.toastr.error(this.translate.instant('ACCOUNT.BALANCE_FAILED_TIP'), `${this.translate.instant('ACCOUNT.BALANCE_FAILED_MESSAGE')}`, {toastClass: 'ngx-toastr yourclass'})
          }
        }

      });
  }

  stopKeepAlive() {
    if (this.keepAliveSub) {
        this.keepAliveSub.unsubscribe();
        // console.log('KA stopped');
    }
  }



  logout(): Observable<{result: {}}> {
    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"session.logout","params":{}})
      .pipe(
        map( (response: ResponseModel) => {
            if ('error' in response){
              throw response;
            }
            return response
          }
        ),
        catchError((error) => {
          return throwError(()=> error)
        })
      )
  }

  resetUserSession(): void {

    this.stopKeepAlive();

    try {
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('cashierUrl');
    } catch(error){
      console.warn('Error accessing localStorage:', error);
    }

    if (this.user){
      this.userService.updateUser(null);
    }
    this._token.next(null);
    this._cashierUrl.next(null);

    //GA tracking
    this.gaService.event('logout',undefined, undefined, undefined, undefined, {...this.trackingService.getAffiliateOptions()});

    //close Rival chat ws connection for logged in user
    if (this.configs.chatSystem === "rival"){
      this.chatService.toggleChat(false);
      this.chatService.closeWebSocket();
    }

    //logout from tawk chat
    if (this.configs.chatSystem === "tawk"){
      //todo
      //throws typerror after logout TypeError: Cannot read properties of undefined (reading '$refs') at a.autoFocusChatInput
      //https://community.tawk.to/t/cannot-read-properties-of-undefined-reading-refs/3419
      //https://community.tawk.to/t/uncaught-typeerror-cannot-read-properties-of-undefined-reading-0/3405
      this.tawkService.LogoutUserFromTawk(); //no log out? because of this typeError
    }

    //show the toastr in auth interceptor for expired token errors



  }

  private restoreTokenAndUrlFromLocalStorage(){
    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (sessionToken) {
        this._token.next(sessionToken);
      }

      // TODO later not to save cashierUrl in localStorage (we wont need this later as we will use our cashier)
      const cashierUrl = localStorage.getItem('cashierUrl');
      if (cashierUrl) {
        this._cashierUrl.next(cashierUrl);
      }
    } catch (error) {
      console.warn('Error accessing localStorage:', error);
    }
  }

  get token(): BehaviorSubject<string | null> {
    return this._token;
  }

  get cashierUrl(): BehaviorSubject<string | null> {
    return this._cashierUrl;
  }

}
